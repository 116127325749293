import React, { useEffect, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import { useSelector, useDispatch } from "react-redux";

import * as authActions from "stores/auth/actions";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import PasswordStrengthBar from "react-password-strength-bar";

import config from "config/global";
import apiDriver from "stores/api.driver";
import { useTranslation } from "react-i18next";
import CountrySelector from "components/AddressForm/CountrySelector";
import PostalCodeInput from "components/AddressForm/PostalCodeInput";
import VatIDInput from "components/AddressForm/VatIDInput";
import useUser from "hooks/useUser";

export function Countries() {
  return [
    {
      code: "pl",
      name: "Polska",
      postalCode: "(([0-9]{2}-[0-9]{3})|([0-9]{5}))",
      phonePrefix: "+48",
      phonePattern:
        "(([0-9]{3}-[0-9]{3}-[0-9]{3})|([0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{2})|([0-9]{3} [0-9]{3} [0-9]{3})|([0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2})|([0-9]{9}))",
    },
  ];
}

function RegisterStep1(props) {
  const { t } = useTranslation();
  const {
    isBusiness,
    setIsBusiness,
    registerData,
    onChangeRegisterData,
    onSubmit,
    getFromGus,
  } = props;
  const [nameFocus, setNameFocus] = useState("");
  const [surnameFocus, setSurnameFocus] = useState("");
  const [businessNameFocus, setBusinessNameFocus] = useState("");

  const [emailFocus, setEmailFocus] = useState("");

  const [passwordFocus, setPasswordFocus] = useState("");

  const [vatFocus, setVatFocus] = useState("");
  const [vat, setVat] = useState("");

  const [repeatPasswordFocus, setRepeatPasswordFocus] = useState("");

  const onChangePasswordScore = (score) => {
    onChangeRegisterData({ target: { name: "passwordScore", value: score } });
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="text-center text-muted mb-4">
        <small>{t("auth.register.header")}</small>
      </div>
      <Row>
        <Col md="6" className="text-left">
          <div className="custom-control custom-radio mb-3">
            <Input
              type="radio"
              id="isBusiness_false"
              className="custom-control-input"
              checked={!isBusiness}
              defaultChecked={!isBusiness}
              onChange={() => setIsBusiness(false)}
            />
            <label className="custom-control-label" htmlFor="isBusiness_false">
              {t("users.types.consumer")}
            </label>
          </div>
          <div className="custom-control custom-radio mb-3">
            <Input
              type="radio"
              id="isBusiness_true"
              className="custom-control-input"
              checked={isBusiness}
              defaultChecked={isBusiness}
              onChange={() => setIsBusiness(true)}
            />
            <label className="custom-control-label" htmlFor="isBusiness_true">
              {t("users.types.business")}
            </label>
          </div>
          <Row>
            <Col lg="6" className="pr-md-0">
              <FormGroup className={"mb-3 " + nameFocus}>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-user"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t("users.fields.givenName")}
                    type="text"
                    name="user.givenName"
                    value={registerData.user.givenName}
                    onChange={onChangeRegisterData}
                    onFocus={() => setNameFocus("focused")}
                    onBlur={() => setNameFocus("")}
                    required={true}
                    minLength={3}
                    maxLength={48}
                    pattern="[^0-9]*"
                  ></Input>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col lg="6" className="pl-md-0">
              <FormGroup className={"mb-3 " + surnameFocus}>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-user"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t("users.fields.surname")}
                    type="text"
                    name="user.surname"
                    value={registerData.user.surname}
                    onChange={onChangeRegisterData}
                    onFocus={() => setSurnameFocus("focused")}
                    onBlur={() => setSurnameFocus("")}
                    required={true}
                    minLength={3}
                    maxLength={48}
                    pattern="[^0-9]*"
                  ></Input>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          {isBusiness ? (
            <FormGroup className={"mb-3 " + vatFocus}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-building"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.fields.vatID")}
                  type="text"
                  name="businessName"
                  value={vat}
                  onChange={(e) => setVat(e.target.value)}
                  onFocus={() => setVatFocus("focused")}
                  onBlur={() => setVatFocus("")}
                  minLength={3}
                  maxLength={48}
                ></Input>
                <InputGroupAddon addonType="append">
                  <Button onClick={() => getFromGus(vat)}>
                    <i className="fas fa-download"></i>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          ) : (
            <React.Fragment />
          )}
          {isBusiness ? (
            <FormGroup className={"mb-3 " + businessNameFocus}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-building"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.fields.businessName")}
                  type="text"
                  name="businessName"
                  value={registerData.businessName}
                  onChange={onChangeRegisterData}
                  onFocus={() => setBusinessNameFocus("focused")}
                  onBlur={() => setBusinessNameFocus("")}
                  required={isBusiness}
                  minLength={3}
                  maxLength={48}
                ></Input>
              </InputGroup>
            </FormGroup>
          ) : (
            <React.Fragment />
          )}
          <FormGroup className={"mb-3 " + emailFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("users.fields.email")}
                type="email"
                name="user.email"
                value={registerData.user.email}
                onChange={onChangeRegisterData}
                onFocus={() => setEmailFocus("focused")}
                onBlur={() => setEmailFocus("")}
                required={true}
                minLength={3}
                maxLength={48}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$"
              ></Input>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="6 mt-md-4 pt-md-5">
          <FormGroup className={passwordFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("users.fields.password")}
                type="password"
                name="user.password"
                value={registerData.user.password}
                onChange={onChangeRegisterData}
                onFocus={() => setPasswordFocus("focused")}
                onBlur={() => setPasswordFocus("")}
                required={true}
                minLength={8}
              ></Input>
            </InputGroup>
            <PasswordStrengthBar
              password={registerData.user.password}
              scoreWords={[
                t("users.password.weak"),
                t("users.password.weak"),
                t("users.password.enough"),
                t("users.password.strong"),
                t("users.password.veryStrong"),
              ]}
              shortScoreWord={t("users.password.tooShort")}
              minLength={8}
              onChangeScore={onChangePasswordScore}
            />
          </FormGroup>
          <FormGroup
            className={`${
              registerData.user.password && registerData.user.repeatPassword
                ? registerData.user.password ===
                  registerData.user.repeatPassword
                  ? "has-success"
                  : "has-danger"
                : ""
            } ${repeatPasswordFocus}`}
          >
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("users.fields.repeatPassword")}
                type="password"
                name="user.repeatPassword"
                value={registerData.user.repeatPassword}
                onChange={onChangeRegisterData}
                onFocus={() => setRepeatPasswordFocus("focused")}
                onBlur={() => setRepeatPasswordFocus("")}
                required={true}
                minLength={8}
              ></Input>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <div className="text-right">
        <Button className="my-4" color="primary" type="submit">
          {t("actions.next")}
        </Button>
      </div>
    </Form>
  );
}

function RegisterStep3(props) {
  const { t } = useTranslation();
  const [addressFocus, setAddressFocus] = useState("");
  const [extraFocus, setExtraFocus] = useState("");
  const [cityFocus, setCityFocus] = useState("");
  const [postalCodeFocus, setPostalCodeFocus] = useState("");
  const [countryCodeFocus, setCountryCodeFocus] = useState("");
  const [personFocus, setPersonFocus] = useState("");
  const [emailFocus, setEmailFocus] = useState("");
  const [phoneFocus, setPhoneFocus] = useState("");

  const {
    onChangeRegisterData,
    registerData,
    onSubmit,
    shippingAddressSameAsBillingInfo,
    loading,
  } = props;

  return (
    <Form onSubmit={onSubmit}>
      <div className="text-center text-muted mb-4">
        <small>{t("customers.shippingAddress.header")}</small>
      </div>
      <Row>
        <Col md="6">
          <FormGroup className={"mb-3 " + countryCodeFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-map-marker"></i>
                </InputGroupText>
              </InputGroupAddon>
              <CountrySelector
                name="shippingAddress.countryCode"
                value={registerData.shippingAddress.countryCode}
                onChange={onChangeRegisterData}
                onFocus={() => setCountryCodeFocus("focused")}
                onBlur={() => setCountryCodeFocus("")}
                required={true}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className={"mb-3 " + addressFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-map"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.line1")}
                type="text"
                name="shippingAddress.line1"
                value={registerData.shippingAddress.line1}
                onChange={onChangeRegisterData}
                onFocus={() => setAddressFocus("focused")}
                onBlur={() => setAddressFocus("")}
                required={true}
                minLength={4}
                maxLength={48}
              ></Input>
            </InputGroup>
          </FormGroup>
          <FormGroup className={"mb-3 " + extraFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-map"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.line2")}
                type="text"
                name="shippingAddress.line2"
                value={registerData.shippingAddress.line2}
                onChange={onChangeRegisterData}
                onFocus={() => setExtraFocus("focused")}
                onBlur={() => setExtraFocus("")}
              ></Input>
            </InputGroup>
          </FormGroup>
          <Row>
            <Col md="4" className="pr-md-0">
              <FormGroup className={"mb-3 " + postalCodeFocus}>
                <InputGroup className="input-group-alternative">
                  <PostalCodeInput
                    countryCode={registerData.shippingAddress.countryCode}
                    placeholder={t("customers.shippingAddress.postalCode")}
                    type="text"
                    name="shippingAddress.postalCode"
                    value={registerData.shippingAddress.postalCode}
                    onChange={onChangeRegisterData}
                    onFocus={() => setPostalCodeFocus("focused")}
                    onBlur={() => setPostalCodeFocus("")}
                    required={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="8" className="pl-md-0">
              <FormGroup className={"mb-3 " + cityFocus}>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder={t("customers.shippingAddress.city")}
                    type="text"
                    name="shippingAddress.city"
                    value={registerData.shippingAddress.city}
                    onChange={onChangeRegisterData}
                    onFocus={() => setCityFocus("focused")}
                    onBlur={() => setCityFocus("")}
                    required={true}
                    minLength={3}
                    maxLength={48}
                  ></Input>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <FormGroup className={"mb-3 " + personFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-user"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.person")}
                type="text"
                name="shippingAddress.person"
                value={registerData.shippingAddress.person}
                onChange={onChangeRegisterData}
                onFocus={() => setPersonFocus("focused")}
                onBlur={() => setPersonFocus("")}
              ></Input>
            </InputGroup>
          </FormGroup>
          <FormGroup className={"mb-3 " + emailFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-envelope"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.email")}
                type="email"
                name="shippingAddress.email"
                value={registerData.shippingAddress.email}
                onChange={onChangeRegisterData}
                onFocus={() => setEmailFocus("focused")}
                onBlur={() => setEmailFocus("")}
                required={true}
              ></Input>
            </InputGroup>
          </FormGroup>
          <FormGroup className={"mb-3 " + phoneFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-phone"></i>
                </InputGroupText>
              </InputGroupAddon>
              <InputGroupAddon>
                <InputGroupText>
                  {Countries().find(
                    (c) => c.code === registerData.shippingAddress.countryCode,
                  )?.phonePrefix || <span>&nbsp;</span>}
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.phone")}
                type="text"
                name="shippingAddress.phone"
                value={registerData.shippingAddress.phone}
                onChange={onChangeRegisterData}
                onFocus={() => setPhoneFocus("focused")}
                onBlur={() => setPhoneFocus("")}
                pattern={
                  Countries().find(
                    (c) => c.code === registerData.shippingAddress.countryCode,
                  )?.phonePattern || ".*"
                }
                required={true}
              ></Input>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <div className="text-right">
        <Button
          className="my-4"
          color="secondary"
          onClick={shippingAddressSameAsBillingInfo}
        >
          {t("customers.shippingAddress.sameAsBillingInfo")}
        </Button>
        <Button
          className="my-4"
          color="primary"
          type="submit"
          disabled={loading?.length}
        >
          {t("auth.register.do")}
        </Button>
      </div>
    </Form>
  );
}

function RegisterStep2(props) {
  const { t } = useTranslation();
  const [businessNameFocus, setBusinessNameFocus] = useState("");
  const [vatNumberFocus, setVatNumberFocus] = useState("");
  const [addressFocus, setAddressFocus] = useState("");
  const [extraFocus, setExtraFocus] = useState("");
  const [cityFocus, setCityFocus] = useState("");
  const [postalCodeFocus, setPostalCodeFocus] = useState("");
  const [countryCodeFocus, setCountryCodeFocus] = useState("");
  const [emailFocus, setEmailFocus] = useState("");

  const { onChangeRegisterData, registerData, onSubmit, isBusiness } = props;

  return (
    <Form onSubmit={onSubmit}>
      <div className="text-center text-muted mb-4">
        <small>{t("customers.billingInfo.header")}</small>
      </div>
      <Row>
        <Col md="6">
          <FormGroup className={"mb-3 " + countryCodeFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-map-marker"></i>
                </InputGroupText>
              </InputGroupAddon>
              <CountrySelector
                name="billingInfo.countryCode"
                value={registerData.billingInfo.countryCode}
                onChange={onChangeRegisterData}
                onFocus={() => setCountryCodeFocus("focused")}
                onBlur={() => setCountryCodeFocus("")}
                required={true}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className={"mb-3 " + addressFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-map"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.line1")}
                type="text"
                name="billingInfo.line1"
                value={registerData.billingInfo.line1}
                onChange={onChangeRegisterData}
                onFocus={() => setAddressFocus("focused")}
                onBlur={() => setAddressFocus("")}
                required={true}
                minLength={4}
                maxLength={48}
              ></Input>
            </InputGroup>
          </FormGroup>
          <FormGroup className={"mb-3 " + extraFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-map"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.line2")}
                type="text"
                name="billingInfo.line2"
                value={registerData.billingInfo.line2}
                onChange={onChangeRegisterData}
                onFocus={() => setExtraFocus("focused")}
                onBlur={() => setExtraFocus("")}
              ></Input>
            </InputGroup>
          </FormGroup>
          <Row>
            <Col md="4" className="pr-md-0">
              <FormGroup className={"mb-3 " + postalCodeFocus}>
                <InputGroup className="input-group-alternative">
                  <PostalCodeInput
                    countryCode={registerData.billingInfo.countryCode}
                    placeholder={t("customers.shippingAddress.postalCode")}
                    type="text"
                    name="billingInfo.postalCode"
                    value={registerData.billingInfo.postalCode}
                    onChange={onChangeRegisterData}
                    onFocus={() => setPostalCodeFocus("focused")}
                    onBlur={() => setPostalCodeFocus("")}
                    required={true}
                    />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="8" className="pl-md-0">
              <FormGroup className={"mb-3 " + cityFocus}>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder={t("customers.shippingAddress.city")}
                    type="city"
                    name="billingInfo.city"
                    value={registerData.billingInfo.city}
                    onChange={onChangeRegisterData}
                    onFocus={() => setCityFocus("focused")}
                    onBlur={() => setCityFocus("")}
                    required={true}
                    minLength={3}
                    maxLength={48}
                  ></Input>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <FormGroup className={"mb-3 " + businessNameFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-building"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.fields.businessName")}
                type="text"
                name="billingInfo.buyerName"
                value={registerData.billingInfo.buyerName}
                onChange={onChangeRegisterData}
                onFocus={() => setBusinessNameFocus("focused")}
                onBlur={() => setBusinessNameFocus("")}
                required={isBusiness}
                minLength={3}
                maxLength={48}
              ></Input>
            </InputGroup>
          </FormGroup>
          <FormGroup className={"mb-3 " + vatNumberFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-coins"></i>
                </InputGroupText>
              </InputGroupAddon>
              <VatIDInput
                countryCode={registerData.billingInfo.countryCode}
                placeholder={t("customers.billingInfo.vatID")}
                type="text"
                name="billingInfo.vatID"
                value={registerData.billingInfo.vatID}
                onChange={onChangeRegisterData}
                onFocus={() => setVatNumberFocus("focused")}
                onBlur={() => setVatNumberFocus("")}
                required={isBusiness}
                />
            </InputGroup>
          </FormGroup>
          <FormGroup className={"mb-3 " + emailFocus}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-envelope"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.billingInfo.email")}
                type="email"
                name="billingInfo.email"
                value={registerData.billingInfo.email}
                onChange={onChangeRegisterData}
                onFocus={() => setEmailFocus("focused")}
                onBlur={() => setEmailFocus("")}
              ></Input>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <div className="text-right">
        <Button className="my-4" color="primary" type="submit">
          {t("actions.next")}
        </Button>
      </div>
    </Form>
  );
}

function RegisterCard(props) {
  const { t } = useTranslation();
  const { step, setStep, getFromGus, error } = props;

  return (
    <>
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-white py-4">
          <CardTitle className="text-center" tag="h4">
            {t("auth.register.registerTitle")}
          </CardTitle>
          <Nav pills fill justified>
            <NavItem>
              <NavLink
                active={step === 1}
                disabled={step < 1}
                onClick={() => setStep(1)}
              >
                1<br />
                <small>{t("auth.register.general")}</small>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={step === 2}
                disabled={step < 2}
                onClick={() => setStep(2)}
              >
                2<br />
                <small>{t("customers.billingInfo.billingInfo")}</small>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={step === 3}
                disabled={step < 3}
                onClick={() => setStep(3)}
              >
                3<br />
                <small>{t("customers.shippingAddress.shippingAddress")}</small>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          {error && (
            <Alert color="danger">
              <p>{error}</p>
            </Alert>
          )}
          {step === 1 && <RegisterStep1 {...props} getFromGus={getFromGus} />}
          {step === 2 && <RegisterStep2 {...props} />}
          {step === 3 && <RegisterStep3 {...props} />}
        </CardBody>
      </Card>
    </>
  );
}

function Register() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [signUpProcessed, setSignUpProcessed] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState([]);
  const [isBusiness, setIsBusiness] = useState(false);
  const [registerData, setRegisterData] = React.useState({
    businessName: "",
    user: {
      givenName: "",
      surname: "",
      email: "",
      password: "",
      repeatPassword: "",
    },
    shippingAddress: {
      line1: "",
      line2: "",
      postalCode: "",
      city: "",
      countryCode: "",
      person: "",
      email: "",
      phone: "",
    },
    billingInfo: {
      line1: "",
      line2: "",
      postalCode: "",
      city: "",
      countryCode: "",
      buyerName: "",
      vatID: "",
      phone: "",
    },
  });

  useEffect(() => {
    if (loading.length === 0 && signUpProcessed) {
      dispatch(
        authActions.login(registerData.user.email, registerData.user.password),
      );
    }
  }, [loading, signUpProcessed]);

  const setLoadingProp = (name, value) => {
    if (value) {
      setLoading([...loading, name]);
    } else {
      setLoading([...loading].filter((l) => l !== name));
    }
  };

  const changeDotNotationValue = (entity, notation, value) => {
    if (typeof notation === "string") {
      return changeDotNotationValue(entity, notation.split("."), value);
    }

    if (notation.length === 1) {
      return { ...entity, [notation[0]]: value };
    } else {
      return {
        ...entity,
        [notation[0]]: changeDotNotationValue(
          entity[notation[0]],
          notation.slice(1),
          value,
        ),
      };
    }
  };

  const onChangeRegisterData = (event) => {
    const { name, value } = event.target;
    const newRegisterData = changeDotNotationValue(
      { ...registerData },
      name,
      value,
    );
    setRegisterData(newRegisterData);
  };

  const signUp = () => {
    setLoadingProp("signUp", true);
    setError(null);
    apiDriver
      .post(config.api.iam + "Authentication/SignUp", {
        ...registerData.user,
        culture: i18n.resolvedLanguage || "pl",
        username: registerData.user.email,
      })
      .subscribe({
        next: onSignUp,
        error: (err) => {
          setError(
            t("auth.errors.register.content") + err.response[0].description,
          );
          setLoadingProp("signUp", false);
        },
      });
  };

  const onSignUp = (signUpResponse) => {
    const token = signUpResponse.response.token;
    const headers = {
      Authorization: token ? `Bearer ${token}` : null,
    };

    setLoading(
      isBusiness
        ? ["business", "shippingAddress", "billingInfo"]
        : ["shippingAddress", "billingInfo"],
    );
    setSignUpProcessed(true);

    if (isBusiness) {
      setLoadingProp("business", true);
      apiDriver
        .put(
          `${config.api.orders}${i18n.resolvedLanguage}/Business/`,
          { title: registerData.businessName },
          headers,
        )
        .subscribe({
          error: () => setError(t("auth.errors.business")),
          complete: () => setLoadingProp("business", false),
        });
    }
    apiDriver
      .put(
        `${config.api.orders}${i18n.resolvedLanguage}/ShippingAddresses/`,
        registerData.shippingAddress,
        headers,
      )
      .subscribe({
        error: () => setError(t("auth.errors.shippingAddress")),
        complete: () => setLoadingProp("shippingAddress", false),
      });
    apiDriver
      .put(
        `${config.api.orders}${i18n.resolvedLanguage}/BillingInfos/`,
        registerData.billingInfo,
        headers,
      )
      .subscribe({
        error: () => setError(t("auth.errors.billingInfo")),
        complete: () => setLoadingProp("billingInfo", false),
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (step < 3) {
      setStep(step + 1);
    } else {
      signUp();
    }
    return false;
  };

  const validate = () => {
    switch (step) {
      case 1:
        if (registerData.user.givenName.length < 3) {
          setError(t("auth.validation.givenName"));
          return false;
        }
        if (registerData.user.surname.length < 3) {
          setError(t("auth.validation.surname"));
          return false;
        }
        if (registerData.user.email.length < 6) {
          setError(t("auth.validation.email"));
          return false;
        }
        if (registerData.user.password.length < 3) {
          setError(t("auth.validation.password"));
          return false;
        }
        if (registerData.user.repeatPassword.length < 3) {
          setError(t("auth.validation.repeatPassword"));
          return false;
        }
        if (registerData.user.password !== registerData.user.repeatPassword) {
          setError(t("auth.validation.passwordsNotThatSame"));
          return false;
        }
        if (isBusiness) {
          if (registerData.businessName.length < 3) {
            setError(t("auth.validation.businessName"));
            return false;
          }
        }
        if (registerData.passwordScore < 2) {
          setError(t("auth.validation.passwordToWeak"));
          return false;
        }
        if (registerData.user.password !== registerData.user.repeatPassword) {
          setError(t("auth.validation.passwordsNotThatSame"));
          return false;
        }
        setError("");
        return true;
      case 2:
        return (
          registerData.billingInfo.line1.length > 2 &&
          registerData.billingInfo.city.length > 2 &&
          registerData.billingInfo.postalCode.length > 2 &&
          registerData.billingInfo.countryCode.length === 2 &&
          registerData.billingInfo.buyerName.length > 2 &&
          ((isBusiness && registerData.buyerName && registerData.vatID) ||
            !isBusiness)
        );
      case 3:
        return (
          registerData.shippingAddress.line1.length > 2 &&
          registerData.shippingAddress.city.length > 2 &&
          registerData.shippingAddress.postalCode.length > 2 &&
          registerData.shippingAddress.countryCode.length === 2
        );
      default:
        return false;
    }
  };

  const getFromGus = (vatID) => {
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/BillingInfos/Gus/${vatID}`,
      )
      .subscribe((response) => {
        if (response.status === 200) {
          setRegisterData({
            ...registerData,
            businessName: response.response.buyerName,
            shippingAddress: response.response,
            billingInfo: response.response,
          });
        } else {
          setError(t("auth.validation.invalidVatId"));
        }
      });
  };

  const shippingAddressSameAsBillingInfo = () => {
    setRegisterData({
      ...registerData,
      shippingAddress: {
        ...registerData.shippingAddress,
        ...registerData.billingInfo,
      },
    });
  };

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>{t("auth.register.registerTitle")} - Printweb.pl</title>
      </Helmet>
      <div className="bg-dark py-5">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col className="px-5 mt-5 pt-5">
                <RegisterCard
                  onChangeRegisterData={onChangeRegisterData}
                  registerData={registerData}
                  onSubmit={onSubmit}
                  validate={validate}
                  step={step}
                  setStep={setStep}
                  isBusiness={isBusiness}
                  setIsBusiness={setIsBusiness}
                  error={error}
                  setError={setError}
                  getFromGus={getFromGus}
                  shippingAddressSameAsBillingInfo={
                    shippingAddressSameAsBillingInfo
                  }
                  loading={loading}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default withRouter(Register);
