import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import Countries from './Countries';

export default function CountrySelector({ value, name, onChange, required, ...props }) {
    const { i18n, t } = useTranslation();

    const _name = name || "countryCode";

    useEffect(() => {
        if(i18n.resolvedLanguage && !value) {
            const foundRegion = Countries.find(country => country.culture == i18n.resolvedLanguage);
            if(foundRegion) {
                onChange({target:{name:_name, type: 'select', value: foundRegion.code}});
            }
        }
    }, [ i18n.resolvedLanguage ])

    const countryNameRegionalizer = new Intl.DisplayNames([i18n.resolvedLanguage], {type: 'region'});

    return <Input
            type="select"
            name={_name}
            value={value}
            onChange={onChange}
            required={required}
            {...props}
        >
            {required ? <option value="" disabled>{t('actions.select')}</option> : <option value="">{t('common.none')}</option>}
            {Countries.map(country => <option key={country.code} value={country.code.toLowerCase()}>{countryNameRegionalizer.of(country.code)} ({country.native}, {country.label})</option>)}
        </Input>
} 